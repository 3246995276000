html, body, #root {
  height: 100%;
}

#root {
  display: flex;
}

@media only screen and (max-width: 800px) {
  header {
    display: none;
  }
}

.text-center {
  text-align: center;
}
